/* You can add global styles to this file, and also import other style files */

html{
  height: 100vh;
}
body { height: 100%; overflow-x: hidden; cursor: context-menu;}
body { margin: 0; font-family: 'Poppins', sans-serif !important; }
.cdk-overlay-backdrop-showing{
    background: #000 !important;
    opacity: 0.5 !important;
    z-index: 999 !important;
  }

  @font-face {
    font-family: 'Montez';
    src: url('assets/fonts/Montez/Montez-Regular.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Bigshot One';
    src: url('assets/fonts/Bigshot_One/BigshotOne-Regular.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Poppins';
    src: url('assets/fonts/Poppins/Poppins-Regular.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Comfortaa';
    src: url('assets/fonts/Comfortaa/Comfortaa-Regular.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Inter';
    src: url('assets/fonts/Inter/Inter_18pt-Regular.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'Istok Web';
    src: url('assets/fonts/Istok_Web/IstokWeb-Regular.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Roboto';
    src: url('assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'Montserrat';
    src: url('assets/fonts/Montserrat/Montserrat-Regular.ttf') format('truetype');
  }


